<template>
  <v-container>
    <v-card :loading="pageLoading" class="shadow-lg mx-12 pb-2">
      <div class="px-8 pt-4">
        <v-btn @click="onBack(false)" depressed>
          <v-icon class="mr-2">mdi-arrow-left</v-icon>
          Go Back to Accounts
        </v-btn>
      </div>
      <v-card-title class="mx-4 mb-0 pb-0">
        <div v-if="user.institution">
          <h3 class="d-block mb-0">
            {{ account._id ? 'Edit' : 'Create' }}
            {{ report.isTestData ? 'Test ' : '' }} Account
          </h3>
        </div>
        <v-spacer></v-spacer>
      </v-card-title>
      <p v-if="user.institution" class="ml-8 mb-8">
        Enter Document details for Common Standard Report (CRS).
      </p>

      <div v-if="!user.institution">
        <img
          src="../../../assets/img/access_denied.svg"
          alt=""
          class="mx-auto mb-4 mt-12"
          style="display: block; width: 170px !important"
        />
        <h1 class="text-center">
          Administrators are not allowed to
          {{ account._id ? 'edit' : 'add' }} account details. <br />
          <v-btn color="primary" class="mx-auto" to="/app/reports"
            >Go Back</v-btn
          >
        </h1>
      </div>

      <div v-else>
        <v-stepper v-model="stepper" flat>
          <v-stepper-header style="box-shadow: none">
            <v-stepper-step
              :rules="[() => stepperHasError !== 1]"
              :editable="true"
              step="1"
              class="ml-0"
            >
              <!-- <v-icon>mdi-card-account-details</v-icon> -->

              <span> Account Details</span>
              <small v-if="stepperHasError == 1">This form is invalid</small>
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              :rules="[() => stepperHasError !== 2]"
              :editable="true"
              step="2"
            >
              <!-- <v-icon>mdi-account-circle-outline</v-icon> -->
              <span>Account Holder</span>
              <small v-if="stepperHasError == 2">This form is invalid</small>
            </v-stepper-step>

            <v-divider></v-divider>
            <v-stepper-step
              :rules="[() => stepperHasError !== 3]"
              :editable="true"
              step="3"
            >
              <!-- <v-icon>mdi-credit-card-outline</v-icon> -->
              <span>Payment Detials</span>

              <small v-if="stepperHasError == 3">This form is invalid</small>
            </v-stepper-step>

            <v-divider v-if="!isIndividualTypeAccount"></v-divider>
            <v-stepper-step
              :rules="[() => stepperHasError !== 4]"
              :editable="true"
              step="4"
              v-if="!isIndividualTypeAccount"
            >
              <!-- <v-icon>mdi-credit-card-outline</v-icon> -->
              <span>Controlling Person</span>
              <small v-if="stepperHasError == 4">This form is invalid</small>
            </v-stepper-step>
          </v-stepper-header>

          <v-divider class="mx-12"></v-divider>

          <!-- Account Details -->
          <v-stepper-content step="1">
            <ValidationObserver ref="accountObserver">
              <v-row class="px-12 pt-8">
                <v-col cols="12" class="py-0">
                  <ValidationProvider
                    name="account type"
                    events="['blur']"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-autocomplete
                      :items="[
                        { code: 'individual', text: 'Individual' },
                        { code: 'organization', text: 'Organization' }
                      ]"
                      :disabled="isUpdating"
                      item-text="text"
                      item-value="code"
                      :error-messages="errors"
                      class="required-v"
                      v-model="account.accountType"
                      label="Account Type"
                      autocomplete="null"
                      id="accountType"
                      @change="onAccountTypeChanged"
                    />
                  </ValidationProvider>
                </v-col>

                <v-col cols="12" class="py-0">
                  <ValidationProvider
                    name="account number"
                    rules="required|max:200"
                    events="['blur']"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      label="Account Number"
                      placeholder=""
                      :error-messages="errors"
                      class="required-v"
                      v-model="account.accountNumber"
                      @blur="onRequiredFieldsChanged"
                      autocomplete="null"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" class="py-0">
                  <ValidationProvider
                    name="account number type"
                    events="['blur']"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-autocomplete
                      :items="accountNumberTypes"
                      item-text="name"
                      item-value="code"
                      :error-messages="errors"
                      class="required-v"
                      v-model="account.accountNumberType"
                      label="Account Number Type"
                      autocomplete="null"
                    />
                  </ValidationProvider>
                </v-col>

                <v-col cols="6" class="py-0">
                  <ValidationProvider
                    name="account balance"
                    :rules="accountStatus == 'Closed' ? 'max_value:0' : ''"
                    events="['blur']"
                    v-slot="{ errors }"
                  >
                    <vuetify-money
                      type="number"
                      label="Account Balance"
                      :error-messages="errors"
                      v-model="account.accountBalance"
                      :options="VuetifyMoneyOptions"
                    >
                    </vuetify-money>
                  </ValidationProvider>
                </v-col>

                <v-col cols="6" class="py-0">
                  <ValidationProvider
                    name="account balance currency"
                    rules="required"
                    events="['blur']"
                    v-slot="{ errors }"
                  >
                    <v-autocomplete
                      :items="currencies"
                      item-text="name"
                      item-value="code"
                      :error-messages="errors"
                      v-model="account.accountCurrency"
                      autocomplete="null"
                    >
                      <template #label>
                        Account Balance Currency
                        <span class="red--text"><strong>* </strong></span>
                      </template>
                    </v-autocomplete>
                  </ValidationProvider>
                </v-col>

                <v-col cols="12">
                  <v-radio-group v-model="accountStatus">
                    <label for="">Account Status</label>
                    <v-radio
                      v-for="n in [
                        'Active',
                        'Closed',
                        'Dormant',
                        'Undocumented'
                      ]"
                      :key="n"
                      :label="`Account ${n}`"
                      :value="n"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </ValidationObserver>
            <v-card-actions class="my-4 mx-8">
              <v-spacer></v-spacer>
              <v-btn text @click="onBack(false)"> Cancel </v-btn>
              <v-btn color="primary" @click="onSubmit(false)"> Next </v-btn>
            </v-card-actions>
          </v-stepper-content>

          <!-- Account Holder Details -->
          <v-stepper-content step="2">
            <ValidationObserver ref="accountHolderObserver">
              <v-row class="px-12 pt-8">
                <v-col cols="6" class="py-0">
                  <ValidationProvider
                    name="tax identification number"
                    rules="max:120"
                    events="['blur']"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      label="Tax Identification Number"
                      :error-messages="errors"
                      class="required-v"
                      v-model="account.accountHolder.taxIdentificationNumber"
                      autocomplete="null"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col cols="6" class="py-0">
                  <ValidationProvider
                    name="country of issue"
                    events="['blur']"
                    v-slot="{ errors }"
                  >
                    <v-autocomplete
                      :items="countries"
                      :item-text="'name'"
                      :item-value="'alphaISO2'"
                      class="required-v"
                      label="Country Of Issue"
                      :error-messages="errors"
                      v-model="account.accountHolder.countryOfIssueISOCode"
                      autocomplete="null"
                    ></v-autocomplete>
                  </ValidationProvider>
                </v-col>

                <v-col cols="6" class="py-0">
                  <ValidationProvider
                    name="country of residence"
                    rules="required"
                    events="['blur']"
                    v-slot="{ errors }"
                  >
                    <v-autocomplete
                      :items="countries"
                      :item-text="'name'"
                      class="required-v"
                      :item-value="'alphaISO2'"
                      label="Country Of Residence"
                      :error-messages="errors"
                      v-model="account.accountHolder.countryOfResidenceISOCode"
                      autocomplete="null"
                    ></v-autocomplete>
                  </ValidationProvider>
                </v-col>

                <v-col cols="6" class="py-0">
                  <ValidationProvider
                    name="name type"
                    rules="required|min:3|max:120"
                    events="['blur']"
                    v-slot="{ errors }"
                  >
                    <v-autocomplete
                      :items="nameTypes"
                      item-text="name"
                      item-value="code"
                      label="Account Holder Name Type"
                      :error-messages="errors"
                      v-model="account.accountHolder.nameType"
                      autocomplete="null"
                    >
                    </v-autocomplete>
                  </ValidationProvider>
                </v-col>

                <v-col cols="6" v-if="!isIndividualTypeAccount" class="py-0">
                  <ValidationProvider
                    name="organization name"
                    rules="required|max:200"
                    events="['blur']"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      label="Organization Name"
                      :error-messages="errors"
                      class="required-v"
                      v-model="account.accountHolder.organizationName"
                      autocomplete="null"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col cols="6" v-if="!isIndividualTypeAccount" class="py-0">
                  <ValidationProvider
                    name="account holder type"
                    rules="required|max:200"
                    events="['blur']"
                    v-slot="{ errors }"
                  >
                    <v-autocomplete
                      :items="accountHolderTypes"
                      item-text="name"
                      item-value="code"
                      label="Account Holder Type"
                      class="required-v"
                      :error-messages="errors"
                      v-model="account.accountHolder.accountHolderType"
                      autocomplete="null"
                    ></v-autocomplete>
                  </ValidationProvider>
                </v-col>

                <v-col cols="3" v-if="isIndividualTypeAccount" class="py-0">
                  <ValidationProvider
                    name="title"
                    rules="max:200"
                    events="['blur']"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      label="Title"
                      :error-messages="errors"
                      v-model="account.accountHolder.title"
                      autocomplete="null"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col cols="3" v-if="isIndividualTypeAccount" class="py-0">
                  <ValidationProvider
                    name="first name"
                    rules="required|max:200"
                    events="['blur']"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      label="First Name"
                      :error-messages="errors"
                      class="required-v"
                      v-model="account.accountHolder.firstName"
                      autocomplete="null"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col cols="3" v-if="isIndividualTypeAccount" class="py-0">
                  <ValidationProvider
                    name="last name"
                    rules="required|max:200"
                    events="['blur']"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      label="Last Name"
                      :error-messages="errors"
                      class="required-v"
                      v-model="account.accountHolder.lastName"
                      autocomplete="null"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col cols="3" v-if="isIndividualTypeAccount" class="py-0">
                  <ValidationProvider
                    name="middle name"
                    rules="max:200"
                    events="['blur']"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      label="Middle Name"
                      :error-messages="errors"
                      v-model="account.accountHolder.middleName"
                      autocomplete="null"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col cols="4" v-if="isIndividualTypeAccount" class="py-0">
                  <ValidationProvider
                    name="generation identifier"
                    rules="max:200"
                    events="['blur']"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="account.accountHolder.generationIdentifier"
                      :error-messages="errors"
                      autocomplete="null"
                    >
                      <template #label> Generation Identifier </template>
                    </v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col cols="4" v-if="isIndividualTypeAccount" class="py-0">
                  <ValidationProvider
                    name="suffix"
                    rules="max:200"
                    events="['blur']"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="account.accountHolder.suffix"
                      :error-messages="errors"
                      autocomplete="null"
                    >
                      <template #label> Suffix </template>
                    </v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col cols="4" v-if="isIndividualTypeAccount" class="py-0">
                  <ValidationProvider
                    name="general suffix"
                    rules="max:200"
                    events="['blur']"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="account.accountHolder.generalSuffix"
                      :error-messages="errors"
                      autocomplete="null"
                    >
                      <template #label> General Suffix </template>
                    </v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col cols="6" v-if="isIndividualTypeAccount" class="py-0">
                  <ValidationProvider
                    name="date of birth"
                    rules="required"
                    events="['blur']"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      type="date"
                      v-model="account.accountHolder.dateOfBirth"
                      :error-messages="errors"
                      autocomplete="null"
                    >
                      <template #label>
                        Date of Birth
                        <span class="red--text"><strong>*</strong></span>
                      </template>
                    </v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col cols="6" v-if="isIndividualTypeAccount" class="py-0">
                  <ValidationProvider
                    name="country of birth"
                    rules="max:200"
                    events="['blur']"
                    v-slot="{ errors }"
                  >
                    <v-autocomplete
                      :items="countries"
                      :item-text="'name'"
                      :item-value="'alphaISO2'"
                      label="Country Of Birth"
                      :error-messages="errors"
                      v-model="account.accountHolder.countryOfBirthISO"
                      autocomplete="null"
                    ></v-autocomplete>
                  </ValidationProvider>
                </v-col>
                <!--- Account Holder Address -->
                <v-col cols="12">
                  <ValidationProvider
                    name="address type"
                    events="['blur']"
                    v-slot="{ errors }"
                  >
                    <v-select
                      :items="addressTypes"
                      item-text="name"
                      item-value="code"
                      label="Address Type"
                      :error-messages="errors"
                      v-model="account.accountHolder.address.addressType"
                      autocomplete="null"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col cols="6" md="6" class="py-0">
                  <ValidationProvider
                    name="street"
                    rules="required|max:200"
                    events="['blur']"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      label="Street"
                      :error-messages="errors"
                      class="required-v"
                      v-model="account.accountHolder.address.street"
                      autocomplete="null"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="6" md="6" class="py-0">
                  <ValidationProvider
                    name="city"
                    rules="required|max:200"
                    events="['blur']"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      label="City"
                      class="required-v"
                      :error-messages="errors"
                      v-model="account.accountHolder.address.city"
                      autocomplete="null"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col cols="6" md="6" class="py-0">
                  <ValidationProvider
                    name="country"
                    rules="required|max:200"
                    events="['blur']"
                    v-slot="{ errors }"
                  >
                    <v-autocomplete
                      :items="countries"
                      :item-text="'name'"
                      :item-value="'alphaISO2'"
                      class="required-v"
                      label="Country"
                      :error-messages="errors"
                      v-model="account.accountHolder.address.countryISOCode"
                      autocomplete="null"
                      @change="onAddressChanged('ac')"
                    ></v-autocomplete>
                  </ValidationProvider>
                </v-col>
                <v-col cols="6" md="6" class="py-0">
                  <ValidationProvider
                    name="province"
                    rules="max:200"
                    events="['blur']"
                    v-slot="{ errors }"
                  >
                    <v-autocomplete
                      :items="accountHolderProvinces"
                      label="Province/State"
                      :error-messages="errors"
                      v-model="account.accountHolder.address.countrySubEntity"
                      autocomplete="null"
                    ></v-autocomplete>
                  </ValidationProvider>
                </v-col>
                <v-col cols="6" md="6" class="py-0">
                  <ValidationProvider
                    name="postal code"
                    rules="max:200"
                    events="['blur']"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      :error-messages="errors"
                      v-model="account.accountHolder.address.postalCode"
                      autocomplete="null"
                    >
                      <template #label> Postal Code </template>
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="6" md="6" class="py-0">
                  <ValidationProvider
                    name="post office box"
                    rules="max:200"
                    events="['blur']"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      label="Post Office Box"
                      :error-messages="errors"
                      v-model="account.accountHolder.address.postOfficeBox"
                      autocomplete="null"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col cols="6" md="6" class="py-0">
                  <ValidationProvider
                    name="building identifier"
                    rules="max:200"
                    events="['blur']"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      label="Building Identifier"
                      :error-messages="errors"
                      v-model="account.accountHolder.address.buildingIdentifier"
                      autocomplete="null"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="6" md="6" class="py-0">
                  <ValidationProvider
                    name="suite identifier"
                    rules="max:200"
                    events="['blur']"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      label="Suite Identifier"
                      :error-messages="errors"
                      v-model="account.accountHolder.address.suiteIdentifier"
                      autocomplete="null"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="6" md="6" class="py-0">
                  <ValidationProvider
                    name="floor identifier"
                    rules="max:200"
                    events="['blur']"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      label="Floor Identifier"
                      :error-messages="errors"
                      v-model="account.accountHolder.address.floorIdentifier"
                      autocomplete="null"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </ValidationObserver>
            <v-card-actions class="my-4 mx-8">
              <v-spacer></v-spacer>
              <v-btn text @click="onBack(true)"> Back </v-btn>
              <v-btn color="primary" @click="onSubmit(false)"> Next </v-btn>
            </v-card-actions>
          </v-stepper-content>

          <!-- Payment Details -->
          <v-stepper-content step="3">
            <ValidationObserver ref="paymentDetailsObserver">
              <v-row class="px-12 pt-8">
                <v-col cols="6" class="py-0">
                  <ValidationProvider
                    name="dividend"
                    events="['blur']"
                    v-slot="{ errors }"
                  >
                    <vuetify-money
                      type="number"
                      label="Dividend"
                      :error-messages="errors"
                      v-model="account.dividend"
                      :options="VuetifyMoneyOptions"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col cols="6" class="py-0">
                  <ValidationProvider
                    name="dividend currency"
                    :rules="`${account.dividend ? 'required|' : ''}max:3`"
                    events="['blur']"
                    v-slot="{ errors }"
                  >
                    <v-select
                      :items="currencies"
                      item-text="name"
                      item-value="code"
                      :error-messages="errors"
                      v-model="account.dividendCurrency"
                      autocomplete="null"
                    >
                      <template #label>
                        Currency
                        <span v-if="account.dividend" class="red--text"
                          ><strong>* </strong></span
                        >
                      </template>
                    </v-select>
                  </ValidationProvider>
                </v-col>

                <v-col cols="6" class="py-0">
                  <ValidationProvider
                    name="interest"
                    events="['blur']"
                    v-slot="{ errors }"
                  >
                    <vuetify-money
                      type="number"
                      label="Interest"
                      :error-messages="errors"
                      v-model="account.interest"
                      :options="VuetifyMoneyOptions"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col cols="6" class="py-0">
                  <ValidationProvider
                    name="interest currency"
                    :rules="`${account.interest ? 'required|' : ''}max:3`"
                    events="['blur']"
                    v-slot="{ errors }"
                  >
                    <v-select
                      :items="currencies"
                      item-text="name"
                      item-value="code"
                      :error-messages="errors"
                      v-model="account.interestCurrency"
                      autocomplete="null"
                    >
                      <template #label>
                        Currency
                        <span v-if="account.interest" class="red--text"
                          ><strong>* </strong></span
                        >
                      </template>
                    </v-select>
                  </ValidationProvider>
                </v-col>

                <v-col cols="6" class="py-0">
                  <ValidationProvider
                    name="gross proceeds"
                    events="['blur']"
                    v-slot="{ errors }"
                  >
                    <vuetify-money
                      type="number"
                      label="Gross Proceeds"
                      :error-messages="errors"
                      v-model="account.grossProceeds"
                      :options="VuetifyMoneyOptions"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col cols="6" class="py-0">
                  <ValidationProvider
                    name="gross proceeds currency"
                    :rules="`${account.grossProceeds ? 'required|' : ''}max:3`"
                    events="['blur']"
                    v-slot="{ errors }"
                  >
                    <v-select
                      :items="currencies"
                      item-text="name"
                      item-value="code"
                      :error-messages="errors"
                      v-model="account.grossProceedsCurrency"
                      autocomplete="null"
                    >
                      <template #label>
                        Currency
                        <span v-if="account.grossProceeds" class="red--text"
                          ><strong>* </strong></span
                        >
                      </template>
                    </v-select>
                  </ValidationProvider>
                </v-col>

                <v-col cols="6" class="py-0">
                  <ValidationProvider
                    name="other payments"
                    events="['blur']"
                    v-slot="{ errors }"
                  >
                    <vuetify-money
                      type="number"
                      label="Other Payments"
                      :error-messages="errors"
                      v-model="account.otherPayments"
                      :options="VuetifyMoneyOptions"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col cols="6" class="py-0">
                  <ValidationProvider
                    name="other payment currency"
                    :rules="`${account.otherPayments ? 'required|' : ''}max:3`"
                    events="['blur']"
                    v-slot="{ errors }"
                  >
                    <v-select
                      :items="currencies"
                      item-text="name"
                      item-value="code"
                      :error-messages="errors"
                      v-model="account.otherPaymentsCurrency"
                      autocomplete="null"
                    >
                      <template #label>
                        Currency
                        <span v-if="account.otherPayments" class="red--text"
                          ><strong>* </strong></span
                        >
                      </template>
                    </v-select>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </ValidationObserver>
            <v-card-actions class="my-4 mx-8">
              <v-spacer></v-spacer>
              <v-btn text @click="onBack(true)"> Back </v-btn>
              <v-btn
                color="primary"
                @click="onSubmit(isIndividualTypeAccount ? true : false)"
              >
                {{ isIndividualTypeAccount ? 'Save' : 'Next' }}
              </v-btn>
            </v-card-actions>
          </v-stepper-content>

          <!-- Controlling Person Details -->
          <v-stepper-content step="4" v-if="!isIndividualTypeAccount">
            <ValidationObserver ref="controllingPersonObserver">
              <v-row class="px-12 pt-8">
                <v-col cols="12" class="py-0">
                  <ValidationProvider
                    name="controlling person type"
                    rules="required"
                    events="['blur']"
                    v-slot="{ errors }"
                  >
                    <v-autocomplete
                      :items="controllingPersonTypes"
                      :item-text="'name'"
                      :item-value="'code'"
                      label="Controlling Person Type"
                      :error-messages="errors"
                      v-model="
                        account.accountHolder.controllingPerson
                          .controllingPersonType
                      "
                    ></v-autocomplete>
                  </ValidationProvider>
                </v-col>
                <v-col cols="6" class="py-0">
                  <ValidationProvider
                    name="tax identification number"
                    rules="required|max:200"
                    events="['blur']"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      label="Tax Identification Number"
                      :error-messages="errors"
                      class="required-v"
                      v-model="
                        account.accountHolder.controllingPerson
                          .taxIdentificationNumber
                      "
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col cols="6" class="py-0">
                  <ValidationProvider
                    name="country of issue"
                    rules="required"
                    events="['blur']"
                    v-slot="{ errors }"
                  >
                    <v-autocomplete
                      :items="countries"
                      :item-text="'name'"
                      :item-value="'alphaISO2'"
                      label="Country Of Issue"
                      class="required-v"
                      :error-messages="errors"
                      v-model="
                        account.accountHolder.controllingPerson
                          .countryOfIssueISOCode
                      "
                    ></v-autocomplete>
                  </ValidationProvider>
                </v-col>

                <v-col cols="6" class="py-0">
                  <ValidationProvider
                    name="name type"
                    events="['blur']"
                    v-slot="{ errors }"
                  >
                    <v-autocomplete
                      :items="nameTypes"
                      item-text="name"
                      item-value="code"
                      label="Controlling Person Name Type"
                      :error-messages="errors"
                      v-model="account.accountHolder.controllingPerson.nameType"
                    >
                    </v-autocomplete>
                  </ValidationProvider>
                </v-col>

                <v-col cols="6" class="py-0">
                  <ValidationProvider
                    name="country of residence"
                    rules="required|max:200"
                    events="['blur']"
                    v-slot="{ errors }"
                  >
                    <v-autocomplete
                      :items="countries"
                      :item-text="'name'"
                      :item-value="'alphaISO2'"
                      label="Country Of Residence"
                      class="required-v"
                      :error-messages="errors"
                      v-model="
                        account.accountHolder.controllingPerson
                          .countryOfResidenceISOCode
                      "
                    ></v-autocomplete>
                  </ValidationProvider>
                </v-col>

                <v-col cols="3" class="py-0">
                  <ValidationProvider
                    name="title"
                    rules="max:200"
                    events="['blur']"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      label="Title"
                      :error-messages="errors"
                      v-model="account.accountHolder.controllingPerson.title"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col cols="3" class="py-0">
                  <ValidationProvider
                    name="first name"
                    rules="required|max:200"
                    events="['blur']"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      label="First Name"
                      :error-messages="errors"
                      class="required-v"
                      v-model="
                        account.accountHolder.controllingPerson.firstName
                      "
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col cols="3" class="py-0">
                  <ValidationProvider
                    name="last name"
                    rules="required|max:200"
                    events="['blur']"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      label="Last Name"
                      :error-messages="errors"
                      class="required-v"
                      v-model="account.accountHolder.controllingPerson.lastName"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col cols="3" class="py-0">
                  <ValidationProvider
                    name="middle name"
                    rules="max:200"
                    events="['blur']"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      label="Middle Name"
                      :error-messages="errors"
                      v-model="
                        account.accountHolder.controllingPerson.middleName
                      "
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col cols="4" class="py-0">
                  <ValidationProvider
                    name="generation identifier"
                    rules="max:200"
                    events="['blur']"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="
                        account.accountHolder.controllingPerson
                          .generationIdentifier
                      "
                      :error-messages="errors"
                    >
                      <template #label> Generation Identifier </template>
                    </v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col cols="4" class="py-0">
                  <ValidationProvider
                    name="suffix"
                    rules="max:200"
                    events="['blur']"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="account.accountHolder.controllingPerson.suffix"
                      :error-messages="errors"
                    >
                      <template #label> Suffix </template>
                    </v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col cols="4" class="py-0">
                  <ValidationProvider
                    name="general suffix"
                    rules="max:200"
                    events="['blur']"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="
                        account.accountHolder.controllingPerson.generalSuffix
                      "
                      :error-messages="errors"
                    >
                      <template #label> General Suffix </template>
                    </v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col cols="6" class="py-0">
                  <ValidationProvider
                    name="date of birth"
                    events="['blur']"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      type="date"
                      v-model="
                        account.accountHolder.controllingPerson.dateOfBirth
                      "
                      :error-messages="errors"
                    >
                      <template #label> Date of Birth</template>
                    </v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col cols="6" class="py-0">
                  <ValidationProvider
                    name="country of birth"
                    rules="max:200"
                    events="['blur']"
                    v-slot="{ errors }"
                  >
                    <v-autocomplete
                      :items="countries"
                      :item-text="'name'"
                      :item-value="'alphaISO2'"
                      label="Country Of Birth"
                      :error-messages="errors"
                      v-model="
                        account.accountHolder.controllingPerson
                          .countryOfBirthISO
                      "
                    ></v-autocomplete>
                  </ValidationProvider>
                </v-col>

                <!--- Account Holder Address -->
                <v-col cols="12">
                  <ValidationProvider
                    name="address type"
                    events="['blur']"
                    v-slot="{ errors }"
                  >
                    <v-select
                      :items="addressTypes"
                      item-text="name"
                      item-value="code"
                      label="Address Type"
                      :error-messages="errors"
                      v-model="
                        account.accountHolder.controllingPerson.address
                          .addressType
                      "
                      autocomplete="off"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col cols="6" md="6" class="py-0">
                  <ValidationProvider
                    name="street"
                    rules="required|max:200"
                    events="['blur']"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      label="Street"
                      :error-messages="errors"
                      class="required-v"
                      v-model="
                        account.accountHolder.controllingPerson.address.street
                      "
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="6" md="6" class="py-0">
                  <ValidationProvider
                    name="city"
                    rules="required|max:200"
                    events="['blur']"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      label="City"
                      class="required-v"
                      :error-messages="errors"
                      v-model="
                        account.accountHolder.controllingPerson.address.city
                      "
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col cols="6" md="6" class="py-0">
                  <ValidationProvider
                    name="country"
                    rules="required|max:200"
                    events="['blur']"
                    v-slot="{ errors }"
                  >
                    <v-autocomplete
                      :items="countries"
                      :item-text="'name'"
                      :item-value="'alphaISO2'"
                      label="Country"
                      class="required-v"
                      :error-messages="errors"
                      v-model="
                        account.accountHolder.controllingPerson.address
                          .countryISOCode
                      "
                      @change="onAddressChanged('cp')"
                    ></v-autocomplete>
                  </ValidationProvider>
                </v-col>
                <v-col cols="6" md="6" class="py-0">
                  <ValidationProvider
                    name="province"
                    rules="max:200"
                    events="['blur']"
                    v-slot="{ errors }"
                  >
                    <v-autocomplete
                      :items="controllingPersonProvinces"
                      label="Province/State"
                      :error-messages="errors"
                      v-model="
                        account.accountHolder.controllingPerson.address
                          .countrySubEntity
                      "
                    ></v-autocomplete>
                  </ValidationProvider>
                </v-col>
                <v-col cols="6" md="6" class="py-0">
                  <ValidationProvider
                    name="postal code"
                    rules="max:200"
                    events="['blur']"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      :error-messages="errors"
                      v-model="
                        account.accountHolder.controllingPerson.address
                          .postalCode
                      "
                    >
                      <template #label> Postal Code </template>
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="6" md="6" class="py-0">
                  <ValidationProvider
                    name="post office box"
                    rules="max:200"
                    events="['blur']"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      label="Post Office Box"
                      :error-messages="errors"
                      v-model="
                        account.accountHolder.controllingPerson.address
                          .postOfficeBox
                      "
                    />
                  </ValidationProvider>
                </v-col>
                <v-col cols="6" md="6" class="py-0">
                  <ValidationProvider
                    name="building identifier"
                    rules="max:200"
                    events="['blur']"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      label="Building Identifier"
                      :error-messages="errors"
                      v-model="
                        account.accountHolder.controllingPerson.address
                          .buildingIdentifier
                      "
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="6" md="6" class="py-0">
                  <ValidationProvider
                    name="suite identifier"
                    rules="max:200"
                    events="['blur']"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      label="Suite Identifier"
                      :error-messages="errors"
                      v-model="
                        account.accountHolder.controllingPerson.address
                          .suiteIdentifier
                      "
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="6" md="6" class="py-0">
                  <ValidationProvider
                    name="floor identifier"
                    rules="max:200"
                    events="['blur']"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      label="Floor Identifier"
                      :error-messages="errors"
                      v-model="
                        account.accountHolder.controllingPerson.address
                          .floorIdentifier
                      "
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </ValidationObserver>
            <v-card-actions class="my-4 mx-8">
              <v-spacer></v-spacer>
              <v-btn text @click="onBack(true)"> Back </v-btn>
              <v-btn color="primary" @click="onSubmit(true)"> Save </v-btn>
            </v-card-actions>
          </v-stepper-content>
        </v-stepper>
      </div>
    </v-card>

    <!-- Modals -->
    <ConfirmationModal
      ref="activeToggleConfirmationModal"
      :title="confirmationTitle"
      subtitle=""
      :message="confirmationMessage"
      confirmColor="error darken-1"
    />
  </v-container>
</template>
<script>
import PageMixin from '@/mixins/page.mixin';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import { VuetifyMoneyOptions } from '@/lib/constants/options';
import { differenceWith, toPairs, isEqual, omit, cloneDeep } from 'lodash';
export default {
  name: 'LoanForm',
  mixins: [PageMixin],
  data: () => ({
    stepper: 1,
    stepperHasError: 0,
    VuetifyMoneyOptions: VuetifyMoneyOptions,
    pageLoading: true,
    report: {},
    account: {
      accountHolder: {
        address: {},
        controllingPerson: {
          address: {}
        }
      },

      address: {}
    },
    confirmationTitle: '',
    confirmationMessage: '',

    accountStatus: 'Active',
    accountHolderProvinces: [],
    controllingPersonProvinces: [],
    unmodifiedAccount: {},
    ignoreKeys: [
      'correctedDocumentReferenceIdentifier',
      '_id',
      'uniqueDocumentIdentifier',
      'dateCreated',
      'dateUpdated',
      'user',
      'report',
      'reportId',
      'version'
    ]
  }),

  async mounted() {
    try {
      this.pageLoading = true;
      const { reportId } = this.$route.params;
      const { accountId } = this.$route.params;
      this.report = await this.fetchReport(reportId);

      if (!this.report) this.$router.push('/app/reports');

      if (accountId) {
        //Check if institution details change

        this.account = await this.findAccount(accountId);

        this.unmodifiedAccount = cloneDeep(this.account);

        if (this.account.isClosed) {
          this.accountStatus = 'Closed';
        } else if (this.account.isDormant) {
          this.accountStatus = 'Dormant';
        } else if (this.account.isUndocumented) {
          this.accountStatus = 'Undocumented';
        }

        if (this.account.accountHolder?.address) {
          this.onAddressChanged('ac');
        }

        if (this.account.accountHolder?.controllingPerson?.address) {
          this.onAddressChanged('cp');
        }

        //check if account is a correction, if so
        //set messageTypeIndicator to CRS702, use old message type indicator to set the correct message type
        //then gnerate a new unique message identifier

        if (this.report.exported) {
          //set new unique document identifier and store old one in correctedDocumentReferenceIdentifier
          this.account.correctedDocumentReferenceIdentifier =
            this.account.uniqueDocumentIdentifier;
          this.account.uniqueDocumentIdentifier =
            await this.generateUniqueAccountID({
              account: this.account,
              user: this.user,
              reportMongoId: reportId
            });
        }
        return;
      }

      const { institution } = this.user;

      this.account = {
        report: this.report._id,
        accountCurrency: institution.defaultCurrency,
        dividendCurrency: institution.defaultCurrency,
        interestCurrency: institution.defaultCurrency,
        grossProceedsCurrency: institution.defaultCurrency,
        otherPaymentsCurrency: institution.defaultCurrency,
        accountHolder: {
          nameType: institution.defaultIndividualNameType,
          address: {},
          controllingPerson: {
            nameType: institution.defaultIndividualNameType,
            address: {
              addressType: institution.defaultIndividualAddressType
            }
          }
        }
      };
    } catch (error) {
      this.httpErrorHandler(error);
    } finally {
      this.pageLoading = false;
    }
  },

  computed: {
    ...mapGetters('coreModule', [
      'countries',
      'messageTypes',
      'accountHolderTypes',
      'accountNumberTypes',
      'currencies',
      'nameTypes',
      'addressTypes',
      'controllingPersonTypes'
    ]),
    ...mapGetters('accountModule', ['accounts']),

    isUpdating() {
      return this.account._id !== undefined;
    },

    isIndividualTypeAccount() {
      return this.account.accountType === 'individual';
    },

    messageTypeIndicators() {
      const isUpdate = this.account._id && this.account.exported ? true : false;
      return this.messageTypes.filter(
        (type) => type.code !== (isUpdate ? 'CRS701' : 'CRS702')
      );
    }
  },

  methods: {
    ...mapActions('reportModule', [
      'createAccount',
      'updateAccount',
      'findAccount',
      'fetchReport',
      'generateUniqueAccountID'
    ]),

    ...mapActions('coreModule', ['getCountryProvinces']),

    onAccountTypeChanged() {
      const { institution } = this.user;

      if (this.account.accountType == 'individual') {
        this.account.accountHolder.nameType =
          institution.defaultIndividualNameType;
        this.account.accountNumberType =
          institution.defaultIndividualAccountNumberType;
        this.account.accountHolder.address.addressType =
          institution.defaultIndividualAddressType;
      } else {
        this.account.accountHolder.nameType =
          institution.defaultOrganizationNameType;
        this.account.accountHolder.accountHolderType =
          institution.defaultAccountHolderType;
        this.account.accountNumberType =
          institution.defaultOrganizationAccountNumberType;
        this.account.accountHolder.address.addressType =
          institution.defaultOrganizationAddressType;
        this.account.accountHolder.controllingPersonType =
          institution.defaultControllingPersonType;
      }
    },

    async onAddressChanged(type) {
      if (type == 'ac') {
        this.accountHolderProvinces = await this.getCountryProvinces({
          key: this.account.accountHolder.address.countryISOCode
        });
        return;
      }

      this.controllingPersonProvinces = await this.getCountryProvinces({
        key: this.account.accountHolder.controllingPerson.address.countryISOCode
      });
    },

    hasAccountChange() {
      return (
        differenceWith(
          toPairs(omit(this.unmodifiedAccount, this.ignoreKeys)),
          toPairs(omit(this.account, this.ignoreKeys)),
          isEqual
        ).length > 0
      );
    },

    async onRequiredFieldsChanged() {
      this.account.uniqueDocumentIdentifier =
        await this.generateUniqueAccountID({
          account: this.account,
          user: this.user,
          reportMongoId: this.report._id
        });
    },

    async onBack(type) {
      if (this.stepper !== 1 && type) {
        this.stepper--;
        return;
      }

      const route = `/app/reports/${this.report._id}/accounts`;
      if (!this.hasAccountChange()) {
        return this.$router.push(route);
      }

      this.confirmationTitle = `You are about to cancel the ${
        this.account._id ? 'edits made to' : 'creation of'
      } this account.`;
      this.confirmationMessage = `Are you sure you want to carry out this action?`;

      return this.$refs.activeToggleConfirmationModal.show(async () => {
        this.$router.push(route);
      });
    },

    async onSubmit(shouldSave) {
      if (!shouldSave) {
        this.stepper++;
        return;
      }

      const isUpdating = !!this.account._id;

      let isValid = await this.$refs.accountObserver.validate();
      if (!isValid) {
        this.stepper = 1;
        this.stepperHasError = 1;
        return;
      }

      isValid = await this.$refs.accountHolderObserver.validate();
      if (!isValid) {
        this.stepper = 2;
        this.stepperHasError = 2;
        return;
      }

      isValid = await this.$refs.paymentDetailsObserver.validate();
      if (!isValid) {
        this.stepper = 3;
        this.stepperHasError = 3;
        return;
      }

      if (this.$refs.controllingPersonObserver) {
        isValid = await this.$refs.controllingPersonObserver.validate();
        if (!isValid) {
          this.stepper = 4;
          this.stepperHasError = 4;
          return;
        }
      }

      const route = `/app/reports/${this.report._id}/accounts`;

      try {
        if (this.account.accountType === 'individual') {
          delete this.account.accountHolder.controllingPerson;
        }

        switch (this.accountStatus) {
          case 'Closed':
            this.account.isClosed = true;
            break;
          case 'Dormant':
            this.account.isDormant = true;
            break;
          case 'Undocumented':
            this.account.isUndocumented = true;
            break;
          default:
            this.account.isClosed = false;
            this.account.isDormant = false;
            this.account.isUndocumented = false;
        }

        this.account.interest = Number(this.account.interest || 0.0);
        this.account.accountBalance = Number(
          this.account.accountBalance || 0.0
        );
        this.account.dividend = Number(this.account.dividend || 0.0);
        this.account.grossProceeds = Number(this.account.grossProceeds || 0.0);
        this.account.otherPayments = Number(this.account.otherPayments || 0.0);

        if (!isUpdating) {
          this.account.isActive = true;
          this.account.documentType = 'OECD1';
          this.account.dateCreated = this.account.dateCreated =
            moment().format();
          await this.createAccount({
            ...this.account
          });
        } else {
          if (!this.hasAccountChange()) {
            this.confirmationTitle = `No changes were made to this account.`;
            this.confirmationMessage = `Do you want to continue editting?`;

            return this.$refs.activeToggleConfirmationModal.show(
              async () => {},
              () => {
                this.$router.push(route);
              }
            );
          }
          this.account.dateUpdated = moment().format('YYYY-MM-DD');
          await this.updateAccount(this.account);
        }

        this.showSnack({
          color: 'success',
          title: `${isUpdating ? 'Updated' : 'Created'}`,
          message: `Account was successfully ${
            isUpdating ? 'updated' : 'created.'
          }.`
        });

        this.$router.push(route);
      } catch (error) {
        this.httpErrorHandler(error);
        throw error;
      }
    }
  }
};
</script>
<style scoped>
.required-v >>> .v-label::after {
  content: ' *' !important;
  color: red !important;
}
</style>